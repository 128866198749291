import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import Head from "./head"
import "../styles.css.ts"
import "./layout-background.css"

export default function Layout(props) {
  return (
    <div>
      <Head {...props} />
      <Header />
      {props.children}
      <Footer />
      <div className='circle xxlarge shade1'></div>
      <div className='circle xlarge shade2'></div>
      <div className='circle large shade3'></div>
      <div className='circle mediun shade4'></div>
      <div className='circle small shade5'></div>
    </div>
  )
}
