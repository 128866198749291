import {
  Link, 
  useI18next
} from 'gatsby-plugin-react-i18next';
import React from 'react';

import {
  FlexList,
  Text,
} from "./ui"

const LanguagePicker = () => {
  const {languages, originalPath} = useI18next();
  return (
    <FlexList>
      {languages.map((lng) => (
        <li key={lng}>
          <Link 
            to={originalPath} 
            language={lng} 
            style={{
              color: "inherit",
              textDecoration: "inherit"
            }}>
            <Text variant="small">
              {lng === "nl" && `Nederlands`}
              {lng === "en-US" && `English`}
            </Text>
          </Link>
        </li>
      ))}
  </FlexList>
  );
};

export default LanguagePicker