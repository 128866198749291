import * as React from 'react'
import { Helmet } from 'react-helmet'

export default function Head ({
  title,
  description,
  image,
  ...props
}) {

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en-us',
      }}>
      <meta charSet='utf-8' />
      <title>{title}</title>
      {description && <meta name='description' property='og:description' content={description} />}
      <meta property='og:title' content={title} />
      {image && <meta property='og:image' content={image.url} />}
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content={title} />
      {description && <meta name='twitter:description' content={description} />}
      {image && <meta name='twitter:image' content={image.url} />}
      <script>{`
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="MDweCPr2D4CGKmBi8nXdSaoqdfFydtMC";;analytics.SNIPPET_VERSION="4.15.3";
        analytics.load("MDweCPr2D4CGKmBi8nXdSaoqdfFydtMC");
        analytics.page();
        }}();
      `}
      </script>
    </Helmet>
  )
}
