import * as React from "react"
import { Container, Kicker, SuperHeading } from "./ui"
import { brandLogoHeader, brandLogoKickerHeader } from "./brand-logo.css.ts"
import { useTranslation } from 'gatsby-plugin-react-i18next';

export default function ShoppingstreetLogo() {
  const { t } = useTranslation()
  return (
    <Container>
      <SuperHeading className={brandLogoHeader}>
        {`Shoppingstreet.io`}
      </SuperHeading>
      <Kicker className={brandLogoKickerHeader}>{t(`We build digital shops`)}</Kicker>
    </Container>
  )
}
