import * as React from "react"
import { Container, Kicker, SuperHeading } from "./ui"
import { brandLogo, brandLogoKicker } from "./brand-logo.css.ts"
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function ShoppingstreetLogo() {
  const { t } = useTranslation()
  return (
    <Container>
      <SuperHeading className={brandLogo}>
        {`Shoppingstreet.io`}
      </SuperHeading>
      <Kicker className={brandLogoKicker}>{t(`We build digital shops`)}</Kicker>
    </Container>
  )
}
